import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import apolsor from "./assets/apolsor.svg"
import './App.css';
import space from "./assets/space.png"
import carousel1 from "./assets/1.png"
import carousel2 from "./assets/2.png"
import carousel3 from "./assets/3.png"
import carousel4 from "./assets/4.png"
import carousel5 from "./assets/5.jpg"
import ray1 from "./assets/ray1.png"
import ray2 from "./assets/ray2.png"
import ray3 from "./assets/ray3.png"
import splash from "./assets/splash.png"
import background from "./assets/background.png"

const App = ({ image }) => {
  const mountRef = useRef(null);
  const [zoom, setZoom] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [pricingOn, setPricingOn] = useState(false)


  const materialRef = useRef();

  const carouselRef = useRef(null);
  const isScrolling = useRef(false);
  const targetScroll = useRef(0);
  const lastScrollLeft = useRef(0);


  useEffect(() => {

  }, [pricingOn]);

  const handlePricing = () => {
    setPricingOn(!pricingOn)
  }

  const smoothScroll = (target) => {
    isScrolling.current = true;
    targetScroll.current = target;

    const animateScroll = () => {
      if (!isScrolling.current) return;

      // Calculate the step for each frame to create an easing effect
      const step = (targetScroll.current - lastScrollLeft.current) * 0.1;
      if (Math.abs(step) < 0.5) {
        carouselRef.current.scrollLeft = targetScroll.current; // Snap to final position
        isScrolling.current = false; // Stop the animation
        return;
      }

      lastScrollLeft.current += step;
      carouselRef.current.scrollLeft = lastScrollLeft.current;
      requestAnimationFrame(animateScroll);
    };

    animateScroll();
  };

  const carouselData = [
    {
      imageSrc: carousel1,
      title: 'Novexa (May 2023)',
      description: 'Click to view full design',
      href: "https://www.figma.com/proto/hRyLxFm43IUW5TVgJqsAjm/Untitled?page-id=0%3A1&type=design&node-id=1-3&viewport=160%2C311%2C0.1&t=hXW7a6VacNNe5R9z-1&scaling=min-zoom&mode=design"
    },
    {
      imageSrc: carousel5,
      title: 'SoundSafari (April 2024)',
      description: 'Click to view full design',
      href: "https://www.soundsafari.io"
    },
    {
      imageSrc: carousel2,
      title: 'Discord Bot (July 2023)',
      description: 'Click to view full design',
      href: "https://www.figma.com/proto/hRyLxFm43IUW5TVgJqsAjm/Untitled?page-id=0%3A1&type=design&node-id=1-245&viewport=160%2C311%2C0.1&t=hXW7a6VacNNe5R9z-1&scaling=min-zoom&mode=design"
    },
    {
      imageSrc: carousel3,
      title: 'Discord Bot (August 2023)',
      description: 'Click to view full design',
      href: "https://www.figma.com/proto/hRyLxFm43IUW5TVgJqsAjm/Untitled?page-id=0%3A1&type=design&node-id=1-458&viewport=160%2C311%2C0.1&t=hXW7a6VacNNe5R9z-1&scaling=min-zoom&mode=design"
    },
    {
      imageSrc: carousel4,
      title: 'Sharemate (February 2024)',
      description: 'Click to view full design',
      href: "https://sharemateapp.com"
    },
    // ...other carousel items
  ];

  useEffect(() => {
    const handleGlobalWheel = (event) => {
      // Avoid handling the global wheel event if we're already scrolling the carousel
      if (isScrolling.current) return;
  
      event.preventDefault(); // Prevent window from scrolling
  
      if (!carouselRef.current) return;
  
      // Determine the direction and set the new active index
      const delta = event.deltaY > 0 ? 1 : -1;
      let newIndex = activeIndex + delta;
      const maxIndex = carouselData.length;
      newIndex = Math.max(0, Math.min(newIndex, maxIndex));
      
      // Update the active index
      setActiveIndex(newIndex);

      let scrollAmount;
  
      // Calculate and perform the smooth scroll
      if (window.innerWidth > 1400) {

        scrollAmount = 944;
      } else {
        scrollAmount = 738;
      }
      const newTargetScroll = newIndex * scrollAmount;
      smoothScroll(newTargetScroll);
    };
  
    // Attach the global wheel event listener
    if (window.innerWidth > 1000) {
      window.addEventListener('wheel', handleGlobalWheel, { passive: false });
    }
  
    return () => {
      // Clean up the global wheel event listener
      window.removeEventListener('wheel', handleGlobalWheel);
    };
  }, [activeIndex, window.innerWidth]); // Depend on activeIndex to update the wheel handler accordingly

  // useEffect(() => {
  //   const handleWheel = (event) => {
  //     event.preventDefault();
  //     if (isScrolling.current) return; // Ignore new wheel events if we're already scrolling

  //     const delta = event.deltaY > 0 ? 1 : -1;
  //     let newIndex = activeIndex + delta;
  //     const maxIndex = 5 - 1; // Assuming there are 5 items
  //     newIndex = Math.max(0, Math.min(newIndex, maxIndex)); // Clamp the value within the valid range
  //     setActiveIndex(newIndex); // Set the new active index

  //     const scrollAmount = 944; // Fixed amount to scroll
  //     const newTargetScroll = carouselRef.current.scrollLeft + delta * scrollAmount;

  //     smoothScroll(newTargetScroll);
  //   };

  //   const carouselEl = carouselRef.current;
  //   carouselEl.addEventListener('wheel', handleWheel, { passive: false });

  //   return () => {
  //     carouselEl.removeEventListener('wheel', handleWheel);
  //   };
  // }, [activeIndex]); // Add activeIndex to the dependency array



  const handleZoom = () => {
    const newZoomState = !zoom;
    setZoom(newZoomState); // Update the state
  };

  const handleUnprice = () => {
    window.location.href = "/"
  }

  


  useEffect(() => {


    if (pricingOn == false) {

      // const updatePlaneSize = () => {
      //   // Make sure the texture has loaded
      //   if (texture.image) {
      //     // Calculate the aspect ratio of the image
      //     const imageAspect = texture.image.width / texture.image.height;
      //     // Get the aspect ratio of the window
      //     const windowAspect = window.innerWidth / window.innerHeight;
      
      //     let planeWidth, planeHeight;
      
      //     // Check the relative aspect ratios to determine how to scale the plane
      //     if (windowAspect > imageAspect) {
      //       // If the window is wider than the image, set the plane's width to the window's width
      //       // and scale the height to maintain the aspect ratio
      //       planeWidth = windowAspect;
      //       planeHeight = windowAspect / imageAspect;
      //     } else {
      //       // If the window is taller than the image, set the plane's height to the window's height
      //       // and scale the width to maintain the aspect ratio
      //       planeWidth = imageAspect / windowAspect;
      //       planeHeight = 1;
      //     }
      
      //     // Apply the scale to the plane geometry
      //     plane.scale.x = planeWidth;
      //     plane.scale.y = planeHeight;
      //   }
      // };



      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer();

      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      const geometry = new THREE.PlaneGeometry(3.25, 1.6);
      const texture = new THREE.TextureLoader().load(background);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          u_time: { type: "f", value: 1.0 },
          u_texture: { type: "t", value: texture },
          u_mouse: { value: { x: 0.5, y: 0.5 } },
          u_effectRadius: { value: 5.0 },
        },
        vertexShader: `
          varying vec2 vUv;
          
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
          `,
        fragmentShader: `
          uniform sampler2D u_texture;
          uniform vec2 u_mouse; // Mouse position in normalized device coordinates
        uniform float u_time;
        uniform float u_zoom;
        uniform float u_effectRadius; // Radius of the effect around the cursor
        varying vec2 vUv;
        
        void main() {
            // Calculate distance from current pixel to mouse position
            vec2 toMouse = vUv - (u_mouse * 0.5 + 0.5); // Adjust if necessary
            float dist = length(toMouse);
            
            // Initialize the warped UVs with the original UVs
            vec2 warpedUVs = vUv;
            
            // Calculate zoom factor based on the zoom state
            // float zoomLevel = u_zoom;

            // Choose the bottom left corner as the zoom center
            // vec2 zoomCenter = vec2(0.0, 0.0);
            
            // Apply the zoom effect
            // Modify the zoom logic as needed, depending on how you want the zoom effect to be
            // vec2 zoomedUV = mix(vUv, zoomCenter, zoomLevel);
            
            // // Calculate zoom factor based on a sine wave over time
            float maxZoom = .05; // Maximum zoom, chosen to maintain image quality
            
            float zoomFactor = sin(u_time * 0.01) * maxZoom + (1.0 - maxZoom);
            
            
            // // Apply the zoom effect
            vec2 center = vec2(0.5, 0.5); // Center of the zoom
            warpedUVs = (warpedUVs - center) * zoomFactor + center;
            
            // Check if the current fragment is within the effect radius
            if (dist < u_effectRadius) {
              // Calculate warp effect based on distance from mouse
              float warpEffect = sin(dist * 1.0 - u_time * 0.05) * 0.01;
              
              // Apply the warp effect by adjusting the UV coordinates
              warpedUVs += (toMouse / dist) * warpEffect * smoothstep(4.0, u_effectRadius * 0.1, dist);
            }
            
            // Sample the texture with the adjusted UVs
            vec4 textureColor = texture2D(u_texture, warpedUVs);
            
            // Output the fragment color
            gl_FragColor = textureColor;
          }
          `
      });

      materialRef.current = material;
      const plane = new THREE.Mesh(geometry, material);

      scene.add(plane);
      camera.position.z = 1;

      const onMouseMove = (event) => {
        // Normalize mouse position to range -0.5 to 0.5, where (0, 0) is the center of the canvas
        material.uniforms.u_mouse.value.x = (event.clientX / window.innerWidth) * 2 - 1;
        material.uniforms.u_mouse.value.y = -((event.clientY / window.innerHeight) * 2 - 1) + 1;
        material.uniforms.u_mouse.value.y -= 1.01;

      };
      window.addEventListener('mousemove', onMouseMove, false);

      const animate = function () {
        requestAnimationFrame(animate);

        material.uniforms.u_time.value += 0.05;
        //     if (materialRef.current) {
        //       if ( zoomRef.current == true ) {
        //     const zoomTarget = zoom ? 1 : 0;
        //     const currentZoom = materialRef.current.uniforms.u_zoom.value;
        //     const zoomSpeed = 0.005; // Adjust this speed for a smoother transition

        //     // Smooth interpolation towards the target value
        //       materialRef.current.uniforms.u_zoom.value += .001;
        //     } 
        //     else if (zoomRef.current == false && materialRef.current.uniforms.u_zoom.value > 0.0) {
        //       materialRef.current.uniforms.u_zoom.value -= .001;
        //     }
        //   // If we're close to the target, clamp the value to the target to stop the animation
        //   // if (Math.abs(zoomTarget - currentZoom) < 0.01) {
        //   //   materialRef.current.uniforms.u_zoom.value = zoomTarget;
        //   // }
        // }
        renderer.render(scene, camera);
      };
      animate();

      const onWindowResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        // updatePlaneSize();
      };

      window.addEventListener('resize', onWindowResize, false);

      return () => {
        if (mountRef.current && renderer.domElement.parentNode === mountRef.current) {
          mountRef.current.removeChild(renderer.domElement);
        }
      };

    } else {
      // Setup the Three.js scene, camera, renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      // Create the particle geometry, material, and points
      const particlesGeometry = new THREE.BufferGeometry();
      const particlesCount = 5000;
      const posArray = new Float32Array(particlesCount * 3);

      for (let i = 0; i < particlesCount * 3; i++) {
        // Position them randomly
        posArray[i] = (Math.random() - 0.5) * 5;
      }

      particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));

      const particlesMaterial = new THREE.PointsMaterial({
        size: 0.005,
        color: 'gray',
      });

      const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
      scene.add(particlesMesh);

      // Mouse movement
      document.addEventListener('mousemove', (event) => {
        // Update particle positions or other properties based on mouse movement
      });

      let frame = 0;
      const animate = () => {
        frame += 0.001;

        // Update particles
        particlesMesh.rotation.y = frame;

        // Render the scene
        renderer.render(scene, camera);

        requestAnimationFrame(animate);
      };

      animate();

      // Clean up on component unmount
      return () => {
        mountRef.current.removeChild(renderer.domElement);
        scene.remove(particlesMesh);
        particlesGeometry.dispose();
        particlesMaterial.dispose();
      };
    }
  }, [image, pricingOn]);



  useEffect(() => {
    if (materialRef.current) {
      // materialRef.current.uniforms.u_zoom.value = zoom ? 1.0 : 0.0;
    }
  }, [zoom, pricingOn]);

  const calculateTimeLeft = () => {
    const targetDate = new Date('December 20, 2024 00:00:00').getTime();
    const now = new Date().getTime();
    const difference = targetDate - now;

    let timeLeft = '00:00:00:00';

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
      const minutes = Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0');
      const seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0');
      timeLeft = `${days}:${hours}:${minutes}:${seconds}`;
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Set up a timer that runs every second
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the timer
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const [listTextItems, setListTextItems] = useState([
    { text: "Mobile & desktop versions (responsive design).", isActive: false },
    { text: "Created in code or any website designer of your choice.", isActive: false },
    { text: "Full ownership of code & design.", isActive: false },
    { text: "Custom SEO strategy to improve rankings and drive traffic.", isActive: false },
    { text: "CSS animations to enhance user experience.", isActive: false },
    { text: "Leading standards for your industry.", isActive: false },
    { text: "Special requests.", isActive: false },
    { text: "Revisions until 14 days after completion.", isActive: false },
    { text: "Money back guaranteed if not satisfied - no risk involved!", isActive: false },
    // ... other items
  ]);

  const [activeListIndex, setActiveListIndex] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveListIndex((current) => {
        // This will increment the index by one or reset to zero if we've reached the end
        const nextIndex = current < listTextItems.length - 1 ? current + 1 : 0;

        // Update the state of each list item based on the nextIndex
        const updatedList = listTextItems.map((item, index) => ({
          ...item,
          isActive: index === nextIndex,
        }));

        setListTextItems(updatedList);

        return nextIndex;
      });
    }, 1000); // Time delay between each activation

    return () => {
      clearInterval(interval);
    };
  }, []);

  // To ensure the first index lights up when the component mounts,
  // you can activate it as soon as the component mounts like so:

  useEffect(() => {
    // Activate the first bullet point when the component mounts
    setListTextItems(listTextItems.map((item, index) => ({
      ...item,
      isActive: index === 0,
    })));
    setActiveListIndex(0); // Set the first bullet point as active
  }, []);

  return (
    <>
      {pricingOn ?

        <div className='pricing'>

          <div className='pricing-content'>
            <img className='logo' src={apolsor}  />
            <div className='pricing-text'>

              <h1><span className='strike'>$999</span> $499 | Ultimate Package</h1>
              <h2>{timeLeft} | Limited time only</h2>

              <div className='list-container'>
                {listTextItems.map((item, index) => (
                  <div key={index} className='list-item'>
                    <div className={item.isActive ? 'bullet-active' : 'bullet-inactive'} />
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
              <br></br>
              <div className='start-here-border start-project' onClick={() => window.location.href = "https://apolsor.com/buy"}>

                <div className='start-here' >
                  Start Your Project Today
                </div>
              </div>
              <p className='contacts'>apolsoraseptu@gmail.com | x.com/apolsor | SMS: (330) 217 6369</p>
            </div>
          </div>

          <div className='particle-container'>
            <div className={`background-animation`} ref={mountRef} />

          </div>
        </div>



        : <div>


          <div className='page-content appear'>

            <img className='logo' src={apolsor} onClick={zoom ? handleZoom : () => { }} />
            <div className={`hero ${zoom ? 'vanish' : ''}`}>

              <h1 className='firsth1'>Stand out from the crowd.</h1>
              <div className='start-here-border'>
                <div className='start-here' onClick={handleZoom}>See Portfolio</div>

              </div>
            </div>
            <div className={`projects ${!zoom ? 'vanish' : 'appear'}`}>
              <div className='carousel' ref={carouselRef}>
                {carouselData.map((item, index) => (
                  <div
                    className={`project-box ${index === activeIndex ? 'active' : 'inactive'}`}
                    style={{ filter: window.innerWidth > 1400 ? index === activeIndex ? 'none' : 'grayscale(100%)' : '' }}
                    key={index}
                    onClick={() => window.location.href = item.href}
                  >
                    <img className='carousel-img' src={item.imageSrc} />
                    {/* {index === activeIndex && ( */}
                      <div className='project-overlay'>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    {/* )} */}
                  </div>
                ))}
                <div className={`project-box1`} >
                  <div className={'carousel-img your-site-here'}>
                    <h2>Your Website Here</h2>
                    <p>After your design is finished, we will display it on this carousel.</p>
                    <div className='start-here see-pricing' onClick={handlePricing}>See Pricing</div>
                  </div>
                </div>

              </div>
              <div className='scrollbar'>
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className={`scrollbar-${index === activeIndex ? 'active' : 'inactive'}`}
                  />
                ))}
              </div>
            </div>

          </div>
          <div className={`rays appear`}>
            <img className={` ${!zoom ? 'vanish' : 'ray1'}`} src={ray1} />
            <img className={` ${!zoom ? 'vanish' : 'ray2'}`} src={ray2} />
            <img className={` ${!zoom ? 'vanish' : 'ray3'}`} src={ray3} />
            <img className={` ${!zoom ? 'vanish' : 'ray4'}`} src={ray2} />
          </div>

          <div className='splash'>
            <img className='splash-logo' src={apolsor}/>
            {/* <img src={splash}/> */}
            <div className='splash-loading'>
              <div className='splash-loading-progress'/>
            </div>
            <p className='loading'>Initializing...</p>
          </div>
          <div className='bg-container'>
            <div className={`background-animation' ${zoom ? 'zoom' : ''}`} ref={mountRef} />

          </div>

        </div>}


    </>
  );
};

export default App;
